import {
  ERROR_FOOTER_FORM,
  SUBMIT_FOOTER_FORM,
  SUCCESS_FOOTER_FORM
} from "../helperFunctions";

const INITIAL_STATE = {
  successNotification: "",
  errorNotification: "",
  footerFormLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMIT_FOOTER_FORM:
      return {
        ...state,
        footerFormLoading: true
      };
    case SUCCESS_FOOTER_FORM:
      return {
        INITIAL_STATE,
        errorNotification: "",
        successNotification: "Thanks for signing up!",
        footerFormLoading: false
      };
    case ERROR_FOOTER_FORM:
      return {
        ...state,
        footerFormLoading: false,
        successNotification: "",
        errorNotification:
          "Failed to subscribe. Please try again or email us at info@nurelm.com."
      };
    default:
      return state;
  }
};
