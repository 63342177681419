import {
  SUBJECT_CHANGED,
  FIRST_NAME_CHANGED,
  LAST_NAME_CHANGED,
  EMAIL_CHANGED,
  PHONE_CHANGED,
  COMMENT_CHANGED,
  SUBMIT_CONTACT_FORM,
  SUCCESS_CONTACT_FORM,
  ERROR_CONTACT_FORM
} from '../helperFunctions';

const INITIAL_STATE = {
  input_4: 'Discussing a new project', //subject
  input_1_3: '', //firstName
  input_1_6: '', //lastName
  input_2: '', //email
  input_6: '', //new phone field without WP API validation
  input_5: '', //comments
  successNotification: '',
  errorNotification: '',
  formLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBJECT_CHANGED:
      return {
        ...state,
        input_4: action.payload
      };
    case FIRST_NAME_CHANGED:
      return {
        ...state,
        input_1_3: action.payload
      };
    case LAST_NAME_CHANGED:
      return {
        ...state,
        input_1_6: action.payload
      };
    case EMAIL_CHANGED:
      return {
        ...state,
        input_2: action.payload
      };
    case PHONE_CHANGED:
      return {
        ...state,
        input_6: action.payload //new field without WP api validation
      };
    case COMMENT_CHANGED:
      return {
        ...state,
        input_5: action.payload
      };
    case SUBMIT_CONTACT_FORM:
      return {
        ...state,
        formLoading: true
      }
    case SUCCESS_CONTACT_FORM:
      return {
        INITIAL_STATE,
        errorNotification: '',
        successNotification: 'Your message has been sent!',
        formLoading: false,
        //TODO: use INITIAL_STATE, this doesn't work, but this does:
        input_4: '', //subject
        input_1_3: '', //firstName
        input_1_6: '', //lastName
        input_2: '', //email
        input_6: '', //new phone, no WPAPI Validation
        input_5: '' //comments
      }
    case ERROR_CONTACT_FORM:
      return {
        ...state,
        formLoading: false,
        successNotification: '',
        errorNotification: 'Failed to send. Please check to make sure required fields are filled out correctly.'
      }
    default:
      return state;
  }
};
