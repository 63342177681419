import {
  FOLLOW_UP_CHANGED,
  FIRST_NAME_CHANGED,
  LAST_NAME_CHANGED,
  EMAIL_CHANGED,
  NEWSLETTER_CHANGED,
  SUBMIT_ABCT_FORM,
  SUCCESS_ABCT_FORM,
  ERROR_ABCT_FORM,
} from "../helperFunctions";

const INITIAL_STATE = {
  input_1_3: "", //firstName
  input_1_6: "", //lastName
  input_3: "", //email
  input_6_1: false, // Follow up checkbox
  input_7_1: true, // Newsletter checkbox
  successNotification: "",
  errorNotification: "",
  formLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FOLLOW_UP_CHANGED:
      return {
        ...state,
        input_6_1: action.payload,
      };
    case FIRST_NAME_CHANGED:
      return {
        ...state,
        input_1_3: action.payload,
      };
    case LAST_NAME_CHANGED:
      return {
        ...state,
        input_1_6: action.payload,
      };
    case EMAIL_CHANGED:
      return {
        ...state,
        input_3: action.payload,
      };
    case NEWSLETTER_CHANGED:
      return {
        ...state,
        input_7_1: action.payload, //new field without WP api validation
      };
    case SUBMIT_ABCT_FORM:
      return {
        ...state,
        formLoading: true,
      };
    case SUCCESS_ABCT_FORM:
      return {
        INITIAL_STATE,
        errorNotification: "",
        successNotification: "Your information has been submitted. Thank you for your interest!",
        formLoading: false,
        //TODO: use INITIAL_STATE, this doesn't work, but this does:
        input_6_1: "", //subject
        input_1_3: "", //firstName
        input_1_6: "", //lastName
        input_3: "", //email
        input_7_1: "", //new phone, no WPAPI Validation
        input_5: "", //comments
      };
    case ERROR_ABCT_FORM:
      return {
        ...state,
        formLoading: false,
        successNotification: "",
        errorNotification:
          "Failed to send. Please check to make sure required fields are filled out correctly.",
      };
    default:
      return state;
  }
};
