export const REDIRECTS = [
  {
    oldPath: '/building-your-research-app',
    newPath: 'files/nurelm-building-your-research-study-app.pdf'
  },
  {
    oldPath: '/web-and-app-samples',
    newPath: 'files/nurelm-web-and-app-samples.pdf'
  },
  {
    oldPath: '/bio-tech-app-samples',
    newPath: 'files/nurelm-bio-tech-app-samples.pdf'
  },
  {
    oldPath: '/themanual',
    newPath: 'the-lab'
  },
  {
    oldPath: '/client/avalara',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/mymedcompanion',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/international-safety-equipment-association',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/airport-corridor-transportation-association',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/arxchange',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/association-of-theological-schools',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/forever-com',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/heatcraft-kysor-warren',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/john-elliott',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/the-watson-institute',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/watson-life-resources',
    newPath: '/our-work/'
  },
  {
    oldPath: '/client/world-affairs-council-of-pittsburgh',
    newPath: '/our-work/'
  },
  {
    oldPath: '/clients',
    newPath: '/our-work/'
  },
  {
    oldPath: '/password-safety',
    newPath: '/the-lab/password-safety'
  },
  {
    oldPath: '/updating-websites-101',
    newPath: '/the-lab/updating-websites-101'
  },
  {
    oldPath: '/a-picture-is-worth-a-thousand-words',
    newPath: '/the-lab/a-picture-is-worth-a-thousand-words'
  },
  {
    oldPath: '/expensive-gas-the-web-and-you',
    newPath: '/the-lab/expensive-gas-the-web-and-you'
  },
  {
    oldPath: '/inside-magento-open-source-ecommerce',
    newPath: '/the-lab/inside-magento-open-source-ecommerce'
  },
  {
    oldPath: '/upgrading-php-and-mysql-on-red-hat-enterprise-linux-4',
    newPath: '/the-lab/upgrading-php-and-mysql-on-red-hat-enterprise-linux-4'
  },
  {
    oldPath: '/designing-for-the-non-designer-part-ii-review-tips-and-trick',
    newPath: '/the-lab/designing-for-the-non-designer-part-ii-review-tips-and-trick'
  },
  {
    oldPath: '/open-video-firefox-html5',
    newPath: '/the-lab/open-video-firefox-html5'
  },
  {
    oldPath: '/text-messaging-for-your-business',
    newPath: '/the-lab/text-messaging-for-your-business'
  },
  {
    oldPath: '/pittsburghs-women-get-techie-wit-it',
    newPath: '/the-lab/pittsburghs-women-get-techie-wit-it'
  },
  {
    oldPath: '/nurelm-get-posts',
    newPath: '/the-lab/nurelm-get-posts'
  },
  {
    oldPath: '/new-kid-on-the-block-css3',
    newPath: '/the-lab/new-kid-on-the-block-css3'
  },
  {
    oldPath: '/10-article-submission-sites-to-help-build-incoming-links',
    newPath: '/the-lab/10-article-submission-sites-to-help-build-incoming-links'
  },
  {
    oldPath: '/how-to-easily-update-your-own-site',
    newPath: '/the-lab/how-to-easily-update-your-own-site'
  },
  {
    oldPath: '/five-easy-ways-to-build-links',
    newPath: '/the-lab/five-easy-ways-to-build-links'
  },
  {
    oldPath: '/designing-for-the-non-designer-part-iii-designing-with-type',
    newPath: '/the-lab/designing-for-the-non-designer-part-iii-designing-with-type'
  },
  {
    oldPath: '/3-common-problems-when-getting-started-with-the-zend-framework-and-xampp',
    newPath: '/the-lab/3-common-problems-when-getting-started-with-the-zend-framework-and-xampp'
  },
  {
    oldPath: '/horizontal-navigation-trends-of-the-future',
    newPath: '/the-lab/horizontal-navigation-trends-of-the-future'
  },
  {
    oldPath: '/mcommerce-ecommerce-on-the-go',
    newPath: '/the-lab/mcommerce-ecommerce-on-the-go'
  },
  {
    oldPath: '/feedback-how-to-keep-everybody-happy',
    newPath: '/the-lab/feedback-how-to-keep-everybody-happy'
  },
  {
    oldPath: '/the-business-guide-to-social-networking-tools',
    newPath: '/the-lab/the-business-guide-to-social-networking-tools'
  },
  {
    oldPath: '/search-engine-trends',
    newPath: '/the-lab/search-engine-trends'
  },
  {
    oldPath: '/rss-revolution-are-you-up-to-date',
    newPath: '/the-lab/rss-revolution-are-you-up-to-date'
  },
  {
    oldPath: '/self-assessment-how-effective-is-your-web-site',
    newPath: '/the-lab/self-assessment-how-effective-is-your-web-site'
  },
  {
    oldPath: '/top-10-perks-of-google-analytics',
    newPath: '/the-lab/top-10-perks-of-google-analytics'
  },
  {
    oldPath: '/top-10-things-to-know-about-adwords',
    newPath: '/the-lab/top-10-things-to-know-about-adwords'
  },
  {
    oldPath: '/ie6-we-need-to-move-on',
    newPath: '/the-lab/ie6-we-need-to-move-on'
  },
  {
    oldPath: '/blog-submissions-10-places-not-to-miss',
    newPath: '/the-lab/blog-submissions-10-places-not-to-miss'
  },
  {
    oldPath: '/using-compelling-content-to-give-your-web-site-a-professional-edge',
    newPath: '/the-lab/using-compelling-content-to-give-your-web-site-a-professional-edge'
  },
  {
    oldPath: '/maintaining-the-effectiveness-of-your-web-site',
    newPath: '/the-lab/maintaining-the-effectiveness-of-your-web-site'
  },
  {
    oldPath: '/easier-cheaper-better-project-management',
    newPath: '/the-lab/easier-cheaper-better-project-management'
  },
  {
    oldPath: '/5-ways-your-college-can-capitalize-on-the-web',
    newPath: '/the-lab/5-ways-your-college-can-capitalize-on-the-web'
  },
  {
    oldPath: '/how-to-roll-up-your-own-social-media-plan',
    newPath: '/the-lab/how-to-roll-up-your-own-social-media-plan'
  },
  {
    oldPath: '/net-tiquette-the-dont-of-social-media-for-business',
    newPath: '/the-lab/net-tiquette-the-dont-of-social-media-for-business'
  },
  {
    oldPath: '/at-the-click-of-a-button',
    newPath: '/the-lab/at-the-click-of-a-button'
  },
  {
    oldPath: '/how-to-be-a-good-client',
    newPath: '/the-lab/how-to-be-a-good-client'
  },
  {
    oldPath: '/bring-flickr-to-your-website-using-json-and-jquery',
    newPath: '/the-lab/bring-flickr-to-your-website-using-json-and-jquery'
  },
  {
    oldPath: '/1-ways-to-rejuvinate',
    newPath: '/the-lab/1-ways-to-rejuvinate'
  },
  {
    oldPath: '/small-business-owners-guide-to-linked-in-and-how-to-make-money-with-it',
    newPath: '/the-lab/small-business-owners-guide-to-linked-in-and-how-to-make-money-with-it'
  },
  {
    oldPath: '/5-ways-to-turn-negative-social-media-feedback-into-a-positive',
    newPath: '/the-lab/5-ways-to-turn-negative-social-media-feedback-into-a-positive'
  },
  {
    oldPath: '/top-free-online-tools-for-nonprofits',
    newPath: '/the-lab/top-free-online-tools-for-nonprofits'
  },
  {
    oldPath: '/javascript-as-a-classical-oop-language',
    newPath: '/the-lab/javascript-as-a-classical-oop-language'
  },
  {
    oldPath: '/self-indulgent-code-jquery-alert-module',
    newPath: '/the-lab/self-indulgent-code-jquery-alert-module'
  },
  {
    oldPath: '/google-invests-100-million-in-well-known-spammer',
    newPath: '/the-lab/google-invests-100-million-in-well-known-spammer'
  },
  {
    oldPath: '/self-indulgent-code-jquery-getjson-with-error-handling',
    newPath: '/the-lab/self-indulgent-code-jquery-getjson-with-error-handling'
  },
  {
    oldPath: '/self-indulgent-code-automatic-tables-from-sql-query-data',
    newPath: '/the-lab/self-indulgent-code-automatic-tables-from-sql-query-data'
  },
  {
    oldPath: '/top-4-online-backup-services',
    newPath: '/the-lab/top-4-online-backup-services'
  },
  {
    oldPath: '/content-creation-for-small-business',
    newPath: '/the-lab/content-creation-for-small-business'
  },
  {
    oldPath: '/self-indulgent-code-asynchronous-process-manager-for-javascript',
    newPath: '/the-lab/self-indulgent-code-asynchronous-process-manager-for-javascript'
  },
  {
    oldPath: '/keyword-research-part-two-of-the-seo-strategies-for-small-business-series',
    newPath: '/the-lab/keyword-research-part-two-of-the-seo-strategies-for-small-business-series'
  },
  {
    oldPath: '/online-privacy-policy-2',
    newPath: '/the-lab/online-privacy-policy-2'
  },
  {
    oldPath: '/writers-block',
    newPath: '/the-lab/writers-block'
  },
  {
    oldPath: '/twitter-power-the-twitter-hashtag',
    newPath: '/the-lab/twitter-power-the-twitter-hashtag'
  },
  {
    oldPath: '/link-building-analysis-part-three-of-seo-strategies-for-small-business',
    newPath: '/the-lab/link-building-analysis-part-three-of-seo-strategies-for-small-business'
  },
  {
    oldPath: '/online-privacy-policy',
    newPath: '/the-lab/online-privacy-policy'
  },
  {
    oldPath: '/an-email-marketing-campaign-for-2010-and-beyond',
    newPath: '/the-lab/an-email-marketing-campaign-for-2010-and-beyond'
  },
  {
    oldPath: '/seo-beginners-guide-seo-strategies-for-small-business-series',
    newPath: '/the-lab/seo-beginners-guide-seo-strategies-for-small-business-series'
  },
  {
    oldPath: '/brand-identity-for-the-social-media-age',
    newPath: '/the-lab/brand-identity-for-the-social-media-age'
  },
  {
    oldPath: '/nurelm-joins-the-movember-fight-against-prostate-cancer',
    newPath: '/the-lab/nurelm-joins-the-movember-fight-against-prostate-cancer'
  },
  {
    oldPath: '/three-tools-to-lower-your-bounce-rate-seo-for-small-business-series',
    newPath: '/the-lab/three-tools-to-lower-your-bounce-rate-seo-for-small-business-series'
  },
  {
    oldPath: '/four-google-analytics-filters-you-should-be-using',
    newPath: '/the-lab/four-google-analytics-filters-you-should-be-using'
  },
  {
    oldPath: '/the-first-steps-in-seo-success-site-analysis',
    newPath: '/the-lab/the-first-steps-in-seo-success-site-analysis'
  },
  {
    oldPath: '/the-nitty-gritty-of-seo-an-indepth-look-at-site-analysis',
    newPath: '/the-lab/the-nitty-gritty-of-seo-an-indepth-look-at-site-analysis'
  },
  {
    oldPath: '/duckduckgo-vs-google-the-little-search-engine-that-might',
    newPath: '/the-lab/duckduckgo-vs-google-the-little-search-engine-that-might'
  },
  {
    oldPath: '/nurelm-2011-new-year-new-rules',
    newPath: '/the-lab/nurelm-2011-new-year-new-rules'
  },
  {
    oldPath: '/what-makes-a-well-designed-ecommerce-site-and-whos-doing-it-right',
    newPath: '/the-lab/what-makes-a-well-designed-ecommerce-site-and-whos-doing-it-right'
  },
  {
    oldPath: '/setting-up-a-virtual-host-with-xampp',
    newPath: '/the-lab/setting-up-a-virtual-host-with-xampp'
  },
  {
    oldPath: '/web-design-vs-seo-whos-right',
    newPath: '/the-lab/web-design-vs-seo-whos-right'
  },
  {
    oldPath: '/what-youre-missing-when-looking-for-backlinks',
    newPath: '/the-lab/what-youre-missing-when-looking-for-backlinks'
  },
  {
    oldPath: '/7-myths-not-to-fall-for-when-looking-for-seo-services',
    newPath: '/the-lab/7-myths-not-to-fall-for-when-looking-for-seo-services'
  },
  {
    oldPath: '/lets-get-truthful-about-social-media-for-seo',
    newPath: '/the-lab/lets-get-truthful-about-social-media-for-seo'
  },
  {
    oldPath: '/how-to-get-started-with-google-analytics-for-the-small-business-owner',
    newPath: '/the-lab/how-to-get-started-with-google-analytics-for-the-small-business-owner'
  },
  {
    oldPath: '/the-ultimate-awesomeness-search',
    newPath: '/the-lab/the-ultimate-awesomeness-search'
  },
  {
    oldPath: '/cakephp-beyond-the-blog-tutorial-part-i',
    newPath: '/the-lab/cakephp-beyond-the-blog-tutorial-part-i'
  },
  {
    oldPath: '/running-cakephp-command-line-tools-in-windows',
    newPath: '/the-lab/running-cakephp-command-line-tools-in-windows'
  },
  {
    oldPath: '/who-is-the-fairest-linux-remoting-server-of-them-all',
    newPath: '/the-lab/who-is-the-fairest-linux-remoting-server-of-them-all'
  },
  {
    oldPath: '/boss-blog-part-1-choosing-the-right-domain-name',
    newPath: '/the-lab/boss-blog-part-1-choosing-the-right-domain-name'
  },
  {
    oldPath: '/cakephp-beyond-the-blog-tutorial-part-ii',
    newPath: '/the-lab/cakephp-beyond-the-blog-tutorial-part-ii'
  },
  {
    oldPath: '/how-to-adopt-a-telecommuting-schedule-within-your-company',
    newPath: '/the-lab/how-to-adopt-a-telecommuting-schedule-within-your-company'
  },
  {
    oldPath: '/boss-blog-part-ii-how-to-select-the-right-theme-for-your-business-blog',
    newPath: '/the-lab/boss-blog-part-ii-how-to-select-the-right-theme-for-your-business-blog'
  },
  {
    oldPath: '/wordpress-recursively-display-a-featured-image-caption-on-child-pages',
    newPath: '/the-lab/wordpress-recursively-display-a-featured-image-caption-on-child-pages'
  },
  {
    oldPath: '/boss-blog-part-iii-must-have-plugins-for-your-new-business-blog',
    newPath: '/the-lab/boss-blog-part-iii-must-have-plugins-for-your-new-business-blog'
  },
  {
    oldPath: '/boss-blog-part-iv-adding-content-to-your-blog',
    newPath: '/the-lab/boss-blog-part-iv-adding-content-to-your-blog'
  },
  {
    oldPath: '/responsive-vs-separate-mobile-websites',
    newPath: '/the-lab/responsive-vs-separate-mobile-websites'
  },
  {
    oldPath: '/mobile-device-testing-processes',
    newPath: '/the-lab/mobile-device-testing-processes'
  },
  {
    oldPath: '/we',
    newPath: '/the-lab/we'
  },
  {
    oldPath: '/drupal-internationalization-and-importing',
    newPath: '/the-lab/drupal-internationalization-and-importing'
  },
  {
    oldPath: '/drupal-bulk-text-format-updates',
    newPath: '/the-lab/drupal-bulk-text-format-updates'
  },
  {
    oldPath: '/self-indulgent-code-why-you-should-be-using-markdown',
    newPath: '/the-lab/self-indulgent-code-why-you-should-be-using-markdown'
  },
  {
    oldPath: '/note-card-monday',
    newPath: '/the-lab/note-card-monday'
  },
  {
    oldPath: '/self-indulgent-code-self-improvment-self-evalutation-edition',
    newPath: '/the-lab/self-indulgent-code-self-improvment-self-evalutation-edition'
  },
  {
    oldPath: '/self-indulgent-code-building-a-responsive-web-tester',
    newPath: '/the-lab/self-indulgent-code-building-a-responsive-web-tester'
  },
  {
    oldPath: '/ssh-for-web-developers-transfer-sites-fast',
    newPath: '/the-lab/ssh-for-web-developers-transfer-sites-fast'
  },
  {
    oldPath: '/self-indulgent-code-self-improvement-what-i-learned-on-coursera',
    newPath: '/the-lab/self-indulgent-code-self-improvement-what-i-learned-on-coursera'
  },
  {
    oldPath: '/create-a-semi-custom-responsive-wordpress-slideshow-plugin',
    newPath: '/the-lab/create-a-semi-custom-responsive-wordpress-slideshow-plugin'
  },
  {
    oldPath: '/the-support-code-website-backups',
    newPath: '/the-lab/the-support-code-website-backups'
  },
  {
    oldPath: '/self-indulgent-code-open-source-game-programming-osgcc6',
    newPath: '/the-lab/self-indulgent-code-open-source-game-programming-osgcc6'
  },
  {
    oldPath: '/nurelm-moves-to-the-city',
    newPath: '/the-lab/nurelm-moves-to-the-city'
  },
  {
    oldPath: '/biking-to-work-101',
    newPath: '/the-lab/biking-to-work-101'
  },
  {
    oldPath: '/learn-to-code',
    newPath: '/the-lab/learn-to-code'
  },
  {
    oldPath: '/what-is-a-content-type-from-a-clients-perspective',
    newPath: '/the-lab/what-is-a-content-type-from-a-clients-perspective'
  },
  {
    oldPath: '/website-project-meetings',
    newPath: '/the-lab/website-project-meetings'
  },
  {
    oldPath: '/nurelm-and-spree',
    newPath: '/the-lab/nurelm-and-spree'
  },
  {
    oldPath: '/nasa-trees-and-flickr',
    newPath: '/the-lab/nasa-trees-and-flickr'
  },
  {
    oldPath: '/quickly-setup-your-ruby-environment-with-rbenv',
    newPath: '/the-lab/quickly-setup-your-ruby-environment-with-rbenv'
  },
  {
    oldPath: '/lead-nirvana',
    newPath: '/the-lab/lead-nirvana'
  },
  {
    oldPath: '/1000s-of-hours-of-work-vs-1-hour-of-api',
    newPath: '/the-lab/1000s-of-hours-of-work-vs-1-hour-of-api'
  },
  {
    oldPath: '/web-developer-conferences-for-pittsburgh-web-developers',
    newPath: '/the-lab/web-developer-conferences-for-pittsburgh-web-developers'
  },
  {
    oldPath: '/debugging-ruby-on-rails-code-snippet',
    newPath: '/the-lab/debugging-ruby-on-rails-code-snippet'
  },
  {
    oldPath: '/debugging-ruby-in-eclipse-code-snippet',
    newPath: '/the-lab/debugging-ruby-in-eclipse-code-snippet'
  },
  {
    oldPath: '/nurelm-returns-to-rustbelt-refresh',
    newPath: '/the-lab/nurelm-returns-to-rustbelt-refresh'
  },
  {
    oldPath: '/design-with-web-fonts-using-typecast',
    newPath: '/the-lab/design-with-web-fonts-using-typecast'
  },
  {
    oldPath: '/another-reason-i-love-ruby-ruby-enumerables',
    newPath: '/the-lab/another-reason-i-love-ruby-ruby-enumerables'
  },
  {
    oldPath: '/what-does-switching-from-rails-to-node-js-really-mean',
    newPath: '/the-lab/what-does-switching-from-rails-to-node-js-really-mean'
  },
  {
    oldPath: '/nurelm-welcomes-perkins-school-for-the-blind',
    newPath: '/the-lab/nurelm-welcomes-perkins-school-for-the-blind'
  },
  {
    oldPath: '/harvest-and-asana',
    newPath: '/the-lab/harvest-and-asana'
  },
  {
    oldPath: '/how-to-override-a-drupal-modules-template-file-when-best-practices-fail',
    newPath: '/the-lab/how-to-override-a-drupal-modules-template-file-when-best-practices-fail'
  },
  {
    oldPath: '/quick-peek-at-drupal-8',
    newPath: '/the-lab/quick-peek-at-drupal-8'
  },
  {
    oldPath: '/a-quick-blast-from-the-past',
    newPath: '/the-lab/a-quick-blast-from-the-past'
  },
  {
    oldPath: '/now-your-code-can-wink-at-you',
    newPath: '/the-lab/now-your-code-can-wink-at-you'
  },
  {
    oldPath: '/vagrant-meets-spree',
    newPath: '/the-lab/vagrant-meets-spree'
  },
  {
    oldPath: '/client-spotlight-fischione-instruments',
    newPath: '/the-lab/client-spotlight-fischione-instruments'
  },
  {
    oldPath: '/sourcetree-the-gui-i-always-wanted-for-git',
    newPath: '/the-lab/sourcetree-the-gui-i-always-wanted-for-git'
  },
  {
    oldPath: '/using-css-font-subsets-to-reduce-page-load',
    newPath: '/the-lab/using-css-font-subsets-to-reduce-page-load'
  },
  {
    oldPath: '/nurelm-welcomes-dragon-door',
    newPath: '/the-lab/nurelm-welcomes-dragon-door'
  },
  {
    oldPath: '/10-tips-to-use-linkedin-the-right-way',
    newPath: '/the-lab/10-tips-to-use-linkedin-the-right-way'
  },
  {
    oldPath: '/vagrant-meets-prestashop',
    newPath: '/the-lab/vagrant-meets-prestashop'
  },
  {
    oldPath: '/5-ways-to-connect-store-to-business',
    newPath: '/the-lab/5-ways-to-connect-store-to-business'
  },
  {
    oldPath: '/drupal-camp-pa',
    newPath: '/the-lab/drupal-camp-pa'
  },
  {
    oldPath: '/drupal-quicktip-changing-your-password-without-admin-access',
    newPath: '/the-lab/drupal-quicktip-changing-your-password-without-admin-access'
  },
  {
    oldPath: '/ie-testing-with-mac-osx',
    newPath: '/the-lab/ie-testing-with-mac-osx'
  },
  {
    oldPath: '/make-store-crm-be-friends-1',
    newPath: '/the-lab/make-store-crm-be-friends-1'
  },
  {
    oldPath: '/using-bundler-with-susy',
    newPath: '/the-lab/using-bundler-with-susy'
  },
  {
    oldPath: '/make-store-crm-be-friends-2',
    newPath: '/the-lab/make-store-crm-be-friends-2'
  },
  {
    oldPath: '/a-quick-drupal-update-guide',
    newPath: '/the-lab/a-quick-drupal-update-guide'
  },
  {
    oldPath: '/uability-testing-with-usabilityhub',
    newPath: '/the-lab/uability-testing-with-usabilityhub'
  },
  {
    oldPath: '/your-git-flow-branching-strategy',
    newPath: '/the-lab/your-git-flow-branching-strategy'
  },
  {
    oldPath: '/make-awesome-visuals-out-of-google-analytics-data',
    newPath: '/the-lab/make-awesome-visuals-out-of-google-analytics-data'
  },
  {
    oldPath: '/client-spotlight-fenner-dunlop',
    newPath: '/the-lab/client-spotlight-fenner-dunlop'
  },
  {
    oldPath: '/harvest-forecast-for-resourcing-web-projects',
    newPath: '/the-lab/harvest-forecast-for-resourcing-web-projects'
  },
  {
    oldPath: '/bend-wordpress-plugin-posts-to-your-will-make-your-own-widget',
    newPath: '/the-lab/bend-wordpress-plugin-posts-to-your-will-make-your-own-widget'
  },
  {
    oldPath: '/organizing-your-schedule-with-the-sunrise-calendar-app',
    newPath: '/the-lab/organizing-your-schedule-with-the-sunrise-calendar-app'
  },
  {
    oldPath: '/vagrant-meets-rails',
    newPath: '/the-lab/vagrant-meets-rails'
  },
  {
    oldPath: '/nurelm-welcomes-sean-gracy',
    newPath: '/the-lab/nurelm-welcomes-sean-gracy'
  },
  {
    oldPath: '/nurelm-welcomes-arxchange',
    newPath: '/the-lab/nurelm-welcomes-arxchange'
  },
  {
    oldPath: '/nurelm-welcomes-isea',
    newPath: '/the-lab/nurelm-welcomes-isea'
  },
  {
    oldPath: '/10-signs-that-your-web-site-is-out-of-date',
    newPath: '/the-lab/10-signs-that-your-web-site-is-out-of-date'
  },
  {
    oldPath: '/open-source-software-what-it-is-and-why-you-should-care',
    newPath: '/the-lab/open-source-software-what-it-is-and-why-you-should-care'
  },
  {
    oldPath: '/common-web-site-mistakes-and-how-to-correct-them',
    newPath: '/the-lab/common-web-site-mistakes-and-how-to-correct-them'
  },
  {
    oldPath: '/nurelm-welcomes-kevin-dadey',
    newPath: '/the-lab/nurelm-welcomes-kevin-dadey'
  },
  {
    oldPath: '/website-colors-and-what-they-say-about-you',
    newPath: '/the-lab/website-colors-and-what-they-say-about-you'
  },
  {
    oldPath: '/thinking-outside-the-web-designing-with-2-0',
    newPath: '/the-lab/thinking-outside-the-web-designing-with-2-0'
  },
  {
    oldPath: '/designing-for-the-non-designer-part-i-the-basics',
    newPath: '/the-lab/designing-for-the-non-designer-part-i-the-basics'
  },
  {
    oldPath: '/overcoming-web-phobia',
    newPath: '/the-lab/overcoming-web-phobia'
  },
  {
    oldPath: '/power-to-the-mommypreneur',
    newPath: '/the-lab/power-to-the-mommypreneur'
  },
  {
    oldPath: '/our-workflow-git-flow',
    newPath: '/the-lab/our-workflow-git-flow'
  },
  {
    oldPath: '/responsive-future-hands',
    newPath: '/the-lab/responsive-future-hands'
  },
  {
    oldPath: '/nurelm-internet-retailer-conference-exhibition',
    newPath: '/the-lab/nurelm-internet-retailer-conference-exhibition'
  },
  {
    oldPath: '/open-source-creative-tools',
    newPath: '/the-lab/open-source-creative-tools'
  },
  {
    oldPath: '/open-source-e-commerce-tools',
    newPath: '/the-lab/open-source-e-commerce-tools'
  },
  {
    oldPath: '/quickbooks-api-integration-1-the-basics',
    newPath: '/the-lab/quickbooks-api-integration-1-the-basics'
  },
  {
    oldPath: '/multiple-rearrangeable-wordpress-content-columns',
    newPath: '/the-lab/multiple-rearrangeable-wordpress-content-columns'
  },
  {
    oldPath: '/rustbelt-refresh-recap',
    newPath: '/the-lab/rustbelt-refresh-recap'
  },
  {
    oldPath: '/credit-card-vendor-may-cost-you-thousands-in-lost-revenues-part-1',
    newPath: '/the-lab/credit-card-vendor-may-cost-you-thousands-in-lost-revenues-part-1'
  },
  {
    oldPath: '/five-years-and-still-rolling',
    newPath: '/the-lab/five-years-and-still-rolling'
  },
  {
    oldPath: '/website-information-architecture-processes-and-trends',
    newPath: '/the-lab/website-information-architecture-processes-and-trends'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-1-introduction',
    newPath: '/the-lab/rustbelt-refresh-recap-part-1-introduction'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-2-derek-featherstone',
    newPath: '/the-lab/rustbelt-refresh-recap-part-2-derek-featherstone'
  },
  {
    oldPath: '/root-a-hearty-stew-of-harvest-quickbooks-and-asana',
    newPath: '/the-lab/root-a-hearty-stew-of-harvest-quickbooks-and-asana'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-3-wren-lanier',
    newPath: '/the-lab/rustbelt-refresh-recap-part-3-wren-lanier'
  },
  {
    oldPath: '/twitterdrinx-a-game',
    newPath: '/the-lab/twitterdrinx-a-game'
  },
  {
    oldPath: '/quickbooks-api-integration-2-the-horrors-of-oauth',
    newPath: '/the-lab/quickbooks-api-integration-2-the-horrors-of-oauth'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-4-beth-dean',
    newPath: '/the-lab/rustbelt-refresh-recap-part-4-beth-dean'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-5-ben-callahan',
    newPath: '/the-lab/rustbelt-refresh-recap-part-5-ben-callahan'
  },
  {
    oldPath: '/front-end-soup-react-polymer-riot-ember-angular-aurelia',
    newPath: '/the-lab/front-end-soup-react-polymer-riot-ember-angular-aurelia'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-6-katie-kovalcin',
    newPath: '/the-lab/rustbelt-refresh-recap-part-6-katie-kovalcin'
  },
  {
    oldPath: '/make-wordpress-content-editing-excellent',
    newPath: '/the-lab/make-wordpress-content-editing-excellent'
  },
  {
    oldPath: '/rustbelt-refresh-recap-part-7-stephanie-hay',
    newPath: '/the-lab/rustbelt-refresh-recap-part-7-stephanie-hay'
  },
  {
    oldPath: '/credit-card-processing-vendors-can-cost-thousands-in-lost-revenues-part-2',
    newPath: '/the-lab/credit-card-processing-vendors-can-cost-thousands-in-lost-revenues-part-2'
  },
  {
    oldPath: '/web-based-vs-desktop-software',
    newPath: '/the-lab/web-based-vs-desktop-software'
  },
  {
    oldPath: '/rustbelt-refresh-recap-conclusion-eric-meyer',
    newPath: '/the-lab/rustbelt-refresh-recap-conclusion-eric-meyer'
  },
  {
    oldPath: '/credit-card-processing-vendors-can-cost-thousands-in-lost-revenues-part-3',
    newPath: '/the-lab/credit-card-processing-vendors-can-cost-thousands-in-lost-revenues-part-3'
  },
  {
    oldPath: '/three-healthcare-projects',
    newPath: '/the-lab/three-healthcare-projects'
  },
  {
    oldPath: '/prestashop-avatax',
    newPath: '/the-lab/prestashop-avatax'
  },
  {
    oldPath: '/deloitte-uses-arxchange-technology-to-help-health-providers-improve-revenues',
    newPath: '/the-lab/deloitte-uses-arxchange-technology-to-help-health-providers-improve-revenues'
  },
  {
    oldPath: '/emcare-better-health-for-young-adults',
    newPath: '/the-lab/emcare-better-health-for-young-adults'
  },
  {
    oldPath: '/nurelm-partners-with-prestashop-at-the-2016-internet-retailers-conference',
    newPath: '/the-lab/nurelm-partners-with-prestashop-at-the-2016-internet-retailers-conference'
  },
  {
    oldPath: '/nurelm-ceo-speaking-at-big-data-conference-in-pittsburgh',
    newPath: '/the-lab/nurelm-ceo-speaking-at-big-data-conference-in-pittsburgh'
  },
  {
    oldPath: '/nurelm-helps-mailchimp-increase-consumer-reach',
    newPath: '/the-lab/nurelm-helps-mailchimp-increase-consumer-reach'
  },
  {
    oldPath: '/nurelm-helps-mine-cancer-data-for-pitt-researchers',
    newPath: '/the-lab/nurelm-helps-mine-cancer-data-for-pitt-researchers'
  },
  {
    oldPath: '/rated-pgh-pittsburgh-film-offices-new-website',
    newPath: '/the-lab/rated-pgh-pittsburgh-film-offices-new-website'
  },
  {
    oldPath: '/match-research-study-mobile-application',
    newPath: '/the-lab/match-research-study-mobile-application'
  },
  {
    oldPath: '/introducing-flowlink',
    newPath: '/the-lab/introducing-flowlink'
  },
  {
    oldPath: '/five-things-love-building-apps-innovators-researchers',
    newPath: '/the-lab/five-things-love-building-apps-innovators-researchers'
  },
  {
    oldPath: '/sneak-peek-at-the-all-new-flowlink',
    newPath: '/the-lab/sneak-peek-at-the-all-new-flowlink'
  },
  {
    oldPath: '/integrate-shopify-ebay-sugarcrm-mailchimp-quickbooks-flowlink',
    newPath: '/the-lab/integrate-shopify-ebay-sugarcrm-mailchimp-quickbooks-flowlink'
  },
  {
    oldPath: '/new-app-to-aid-researchers-in-the-effort-against-binge-drinking',
    newPath: '/the-lab/new-app-to-aid-researchers-in-the-effort-against-binge-drinking'
  },
  {
    oldPath: '/automated-marketing-mailchimps-new-prestashop-integration',
    newPath: '/the-lab/automated-marketing-mailchimps-new-prestashop-integration'
  },
  {
    oldPath: '/client-spotlight-john-elliott',
    newPath: '/the-lab/client-spotlight-john-elliott'
  },
  {
    oldPath: '/office-management-applications-use',
    newPath: '/the-lab/office-management-applications-use'
  },
  {
    oldPath: '/integrate-quickbooks-desktop-online-store',
    newPath: '/the-lab/integrate-quickbooks-desktop-online-store'
  },
  {
    oldPath: '/integrate-netsuite-tool',
    newPath: '/the-lab/integrate-netsuite-tool'
  },
  {
    oldPath: '/web-app-development-overview-researchers-business-professionals',
    newPath: '/the-lab/web-app-development-overview-researchers-business-professionals'
  },
  {
    oldPath: '/integrate-shopify-anything',
    newPath: '/the-lab/integrate-shopify-anything'
  },
  {
    oldPath: '/web-app-development',
    newPath: '/the-lab/web-app-development'
  },
  {
    oldPath: '/mobile-apps-deconstructed-tutorial-series-business-professionals',
    newPath: '/the-lab/mobile-apps-deconstructed-tutorial-series-business-professionals'
  },
  {
    oldPath: '/ssl-certificates-need-one',
    newPath: '/the-lab/ssl-certificates-need-one'
  },
  {
    oldPath: '/integrate-bergen-logistics-anything',
    newPath: '/the-lab/integrate-bergen-logistics-anything'
  },
  {
    oldPath: '/3-api-hints-business-professionals',
    newPath: '/the-lab/3-api-hints-business-professionals'
  },
  {
    oldPath: '/freelancer-web-dev-firm-next-web-app-project',
    newPath: '/the-lab/freelancer-web-dev-firm-next-web-app-project'
  },
  {
    oldPath: '/writing-basics-for-websites',
    newPath: '/the-lab/writing-basics-for-websites'
  },
  {
    oldPath: '/easily-integrate-slack-anything',
    newPath: '/the-lab/easily-integrate-slack-anything'
  }
  // {
  //   oldPath: '/the-lab/easily-integrate-slack-anything', 
  //   newPath: 'https://flowlink.io/easily-integrate-slack-with-anything'
  // },
  // {
  //   oldPath: '/the-lab/integrate-quickbooks-desktop-online-store', 
  //   newPath: 'https://flowlink.io/integrate-quickbooks-desktop-with-any-online-store'
  // },
  // {
  //   oldPath: '/the-lab/integrate-netsuite-tool', 
  //   newPath: 'https://flowlink.io/integrate-netsuite-with-any-tool'
  // },
  // {
  //   oldPath: '/the-lab/integrate-shopify-anything', 
  //   newPath: 'https://flowlink.io/integrate-shopify-with-anything'
  // },
  // {
  //   oldPath: '/integrate-bronto-anything', 
  //   newPath: '/integrate-bronto-with-anythingggg'
  // },
  // {
  //   oldPath: '/the-lab/integrate-bergen-logistics-anything', 
  //   newPath: 'https://flowlink.io/integrate-bergen-logistics-with-anything'
  // }
]