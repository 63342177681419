const INITIAL_STATE = {
  heroAnimation: "",
  navigationOpen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'update_animation':
      return {
        ...state,
        heroAnimation: action.swapAnimation
      }
    case 'clear_animation':
      return {
        ...state,
        heroAnimation: ""
      };
    case 'open_navigation':
      return {
        ...state,
        navigationOpen: !state.navigationOpen
      }
    case 'close_nav_if_open':
      return {
        ...state,
        navigationOpen: false
      }
    default:
      return state;

  }
};
