import { ERROR_NEWSLETTER_FORM, SUBMIT_NEWSLETTER_FORM, SUCCESS_NEWSLETTER_FORM } from "../helperFunctions";
const INITIAL_STATE = {
  successNotification: "",
  errorNotification: "",
  newsletterFormLoading: false
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBMIT_NEWSLETTER_FORM:
      return {
        ...state,
        newsletterFormLoading: true
      };
    case SUCCESS_NEWSLETTER_FORM:
      return {
        INITIAL_STATE,
        errorNotification: "",
        successNotification: "Thanks for signing up!",
        newsletterFormLoading: false
      };
    case ERROR_NEWSLETTER_FORM:
      return {
        ...state,
        newsletterFormLoading: false,
        successNotification: "",
        errorNotification:
          "Failed to subscribe. Please try again or email us at info@nurelm.com."
      };
    default:
      return state;
  }
};
