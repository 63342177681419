import { combineReducers } from 'redux';
import sharedPiecesReducer from './sharedPiecesReducer';
import contactFormReducer from './contactFormReducer';
import newsletterFormReducer from "./newsletterFormReducer";
import footerFormReducer from "./footerFormReducer";
import abctFormReducer from './abctFormReducer';

export default combineReducers({
  sharedPieces: sharedPiecesReducer,
  contactForm: contactFormReducer,
  newsletterForm: newsletterFormReducer,
  footerForm: footerFormReducer,
  abctForm: abctFormReducer,
});
