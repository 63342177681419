import React from 'react';
import Loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';
import { REDIRECTS } from './util/redirects';
import '@wordpress/block-library/build-style/common.css';
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';
import './App.css';

const Loading = () => <div />;

const AsyncHome = Loadable({
  loader: () => import('./pages/Home/Home'),
  loading: Loading
})
const AsyncOurWork = Loadable({
  loader: () => import('./pages/OurWork/OurWork'),
  loading: Loading
})
const AsyncDetailsOurWork = Loadable({
  loader: () => import('./pages/OurWork/DetailsOurWork'),
  loading: Loading
})
const AsyncOurApproach = Loadable({
  loader: () => import('./pages/OurApproach/OurApproach'),
  loading: Loading
})
const AsyncOurCompany = Loadable({
  loader: () => import('./pages/OurCompany/OurCompany'),
  loading: Loading
})
const AsyncTheLab = Loadable({
  loader: () => import('./pages/TheLab/TheLab'),
  loading: Loading
})
const AsyncTheLabDetail = Loadable({
  loader: () => import('./pages/TheLab/TheLabDetail'),
  loading: Loading
})
const AsyncContact = Loadable({
  loader: () => import('./pages/Contact/Contact'),
  loading: Loading
})
const AsyncMobileApps = Loadable({
  loader: () => import('./pages/MobileApps/MobileApps'),
  loading: Loading
})
const AsyncLandingPage = Loadable({
  loader: () => import('./pages/LandingPage/LandingPage'),
  loading: Loading
})
const AsyncNewsletter = Loadable({
  loader: () => import('./pages/Newsletter/Newsletter'),
  loading: Loading
})
const AsyncNotFound = Loadable({
  loader: () => import('./pages/NotFound/NotFound'),
  loading: Loading
})
const AsyncPrivacyPolicy = Loadable({
  loader: () => import('./pages/PrivacyPolicy/PrivacyPolicy'),
  loading: Loading
})
const AsyncABCTLandingPage = Loadable({
  loader: () => import("./pages/LandingPage/ABCTLandingPage"),
  loading: Loading,
});

const App = () => {
  return (
    <Switch>
      <Route exact path="/" component={AsyncHome} />
      <Route path="/our-approach" component={AsyncOurApproach} />
      <Route path="/our-company" component={AsyncOurCompany} />
      <Route exact path="/our-work" component={AsyncOurWork} />
      <Route path="/our-work/:slug" component={AsyncDetailsOurWork} />
      <Route exact path="/the-lab" component={AsyncTheLab} />
      <Route path="/the-lab/:slug" component={AsyncTheLabDetail} />
      <Route path="/contact-us" component={AsyncContact} />
      <Route
        path="/pittsburghs-cross-platform-mobile-app-experts"
        component={AsyncMobileApps}
      />
      <Route
        path="/mobile-apps-for-university-research-studies"
        component={AsyncLandingPage}
      />
      <Route path="/newsletter" component={AsyncNewsletter} />
      <Route path="/privacy-policy" component={AsyncPrivacyPolicy} />
      <Route path="/abct" component={AsyncABCTLandingPage} />
      {REDIRECTS.map((item) => (
        <Route
          key={item.oldPath}
          path={item.oldPath}
          render={() => {
            window.location.replace(item.newPath);
          }}
        />
      ))}
      <Route path="*" component={AsyncNotFound} />
    </Switch>
  );
}

export default App;