import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import { BrowserRouter } from 'react-router-dom'

import './index.css';
import reducer from './reducers';

const store = createStore(reducer, compose(
  applyMiddleware(thunk),
));
// Need to add some kind of persistence here

const queryClient = new QueryClient()

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
